import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { memberWithSearch } from "src/DAL/member/Member";
import {
  EditPaymentRequestApi,
  addPaymentRequest,
  memberBillingDetailApi,
} from "src/DAL/PaymentRequest/paymentRequest";
import { MainTerminalProgramListing } from "src/DAL/Program/Programs";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import SelectCountry from "src/components/GeneralComponents/SelectCountry";
import { projectMode } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_DATA = {
  request_title: "",
  currency: "gbp",
  total_amount: "",
  vat_number: "",
  transaction_note: "",
  payment_template: null,
  template_type: "onetime",
  product: null,
  status: true,
  programme: null,
  member: "",
  initial_amount: "",
  is_member_create: false,
  no_of_installment: 0,
  installment_amount: "",
  request_iteration_type: "month",
  first_name: "",
  last_name: "",
  email: "",
  number_of_days: "0",
};

const get_full_name = (user) => {
  return user.first_name + " " + user.last_name + " (" + user.email + ")";
};

export default function AddOrUpdatePaymentRequest() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { request_slug } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [membersList, setMembersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [products, setProducts] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [installmentAmount, setInstallmentAmount] = useState(0);
  const [member, setMember] = useState(null);
  const [serchText, setSerchText] = useState("");
  const [inputs, setInputs] = useState(EMPTY_DATA);

  const get_list_of_data = async () => {
    let postData = {
      filter_array: ["program", "payment_template", "stripe_products"],
    };
    const result = await MainTerminalProgramListing(postData);
    if (result.code == 200) {
      setProgrammes(result.programs);
      setTemplates(result.payment_template);
      setProducts(result.products);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_request_detail = async () => {
    const result = await memberBillingDetailApi(request_slug);
    if (result.code == 200) {
      handleFormatData(result.payment_request);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getMemberList = async () => {
    const result = await memberWithSearch(serchText);
    if (result.code === 200) {
      let members = [];
      if (result.members.length > 0) {
        members = result.members.map((user) => {
          user.full_name = get_full_name(user);
          return user;
        });
      }
      setMembersList(members);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.is_member_create && !country) {
      enqueueSnackbar("Select member's country", { variant: "error" });
      return;
    }
    setIsLoading(true);
    let postData = {
      request_title: inputs.request_title,
      request_type: inputs.template_type,
      total_amount: inputs.total_amount,
      currency: inputs.currency,
      vat_number: inputs.vat_number,
      transaction_note: inputs.transaction_note,
      product: inputs.product,
      status: inputs.status,
      is_member_create: inputs.is_member_create,
      payment_mode: projectMode == "DEV" ? "sandBox" : "live",
      show_on_consultant: "all",
    };

    if (inputs.product) {
      postData.product = inputs.product._id;
    }
    if (inputs.programme) {
      postData.program_slug = inputs.programme.program_slug;
    }
    if (inputs.payment_template) {
      postData.payment_template = inputs.payment_template._id;
    }

    if (inputs.template_type === "recurring") {
      postData.initial_amount = inputs.initial_amount;
      postData.month = inputs.no_of_installment;
      postData.request_iteration_type = inputs.request_iteration_type;

      if (inputs.request_iteration_type == "custom") {
        postData.number_of_days = inputs.number_of_days;
      }
    }

    if (!request_slug && inputs.is_member_create) {
      postData.first_name = inputs.first_name;
      postData.last_name = inputs.last_name;
      postData.email = inputs.email;
      postData.country = country.code;
    } else {
      postData.member_id = member._id;
    }

    const result = request_slug
      ? await EditPaymentRequestApi(postData, request_slug)
      : await addPaymentRequest(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/payment-request`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeTemplate = async (value) => {
    console.log(value, "handleChangeTemplate");
    if (value) {
      setInputs((old) => ({
        ...old,
        ...value,
        payment_template: value,
        programme: value.program,
        request_iteration_type: value.interval_type,
        request_title: value.title,
      }));
    } else {
      setInputs((old) => ({ ...old, payment_template: value }));
    }
  };

  const handleFormatData = (data) => {
    setInputs({
      ...data,
      template_type: data.request_type,
      no_of_installment: data.month,
    });
    let { member } = data;
    member.full_name = get_full_name(data.member);
    setMember(member);
    setIsLoading(false);
  };

  useEffect(() => {
    get_list_of_data();
    if (request_slug) {
      setIsLoading(true);
      if (state) {
        handleFormatData(state);
      } else {
        get_request_detail();
      }
    }
  }, []);

  useEffect(() => {
    getMemberList();
  }, [serchText]);

  useEffect(() => {
    let totalValue = inputs.total_amount - inputs.initial_amount;
    let installment = (totalValue / inputs.no_of_installment).toFixed(1);
    setInstallmentAmount(installment);
  }, [inputs.total_amount, inputs.no_of_installment, inputs.initial_amount]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <form className="container" onSubmit={handleSubmit}>
      <div className="row mobile-margin display-flex mt-2">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/payment-request`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">{`${
            request_slug ? "Edit" : "Add"
          } Payment Request`}</h2>
        </div>
      </div>

      {!request_slug && (
        <div className="row mt-3">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-2">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Member Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_member_create"
                value={inputs.is_member_create}
                label="Member"
                onChange={handleChange}
                required
              >
                <MenuItem value={false}>Add Existing Member</MenuItem>
                <MenuItem value={true}>Add New Member</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      )}
      {!request_slug && (
        <div className="mt-4 p-3 p-md-4 persons-list-billing">
          <div className="row">
            <div className="col-12">
              <h3>Member Info</h3>
            </div>
            {inputs.is_member_create ? (
              <>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-2">
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    name="first_name"
                    value={inputs.first_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-2">
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    name="last_name"
                    value={inputs.last_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    name="email"
                    value={inputs.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <SelectCountry
                    selectedCountry={country}
                    onChange={setCountry}
                  />
                </div>
              </>
            ) : (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-2">
                <MUIAutocomplete
                  inputLabel="Members"
                  selectedOption={member}
                  setSelectedOption={setMember}
                  optionsList={membersList}
                  setCustomSearch={setSerchText}
                  name="full_name"
                  required
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className={`mt-4 ${
          request_slug ? "" : "p-3 p-md-4 persons-list-billing"
        } `}
      >
        <div className="row">
          {!request_slug && <h3>Payment Request Info</h3>}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-2">
            <MUIAutocomplete
              inputLabel="Payment Template Type"
              selectedOption={inputs.payment_template}
              setSelectedOption={(value) => {
                handleChangeTemplate(value);
              }}
              optionsList={templates}
              name="title"
            />
          </div>
          {request_slug && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-2">
              <MUIAutocomplete
                inputLabel="Members"
                selectedOption={member}
                setSelectedOption={setMember}
                optionsList={membersList}
                setCustomSearch={setSerchText}
                name="full_name"
                required
              />
            </div>
          )}
          <div
            className={`col-lg-6 col-md-6 col-sm-12 mt-4 ${
              request_slug ? "" : "mt-md-2"
            }`}
          >
            <TextField
              id="outlined-basic"
              label="Request Title"
              variant="outlined"
              fullWidth
              required
              name="request_title"
              disabled={Boolean(inputs.payment_template)}
              value={inputs.request_title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                disabled={Boolean(inputs.payment_template)}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" required>
                Currency
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="currency"
                value={inputs.currency}
                disabled={true}
                label="currency"
                onChange={handleChange}
                required
              >
                <MenuItem value="usd">Dollar</MenuItem>
                <MenuItem value="gbp">UK Pounds</MenuItem>
                <MenuItem value="eur">Euro</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Product"
              selectedOption={inputs.product}
              setSelectedOption={(value) => {
                handleChangeOthers("product", value);
              }}
              optionsList={products}
              disabled={Boolean(inputs.payment_template)}
              required
              name="name"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Programme"
              selectedOption={inputs.programme}
              setSelectedOption={(value) => {
                handleChangeOthers("programme", value);
              }}
              optionsList={programmes}
              disabled={Boolean(inputs.payment_template)}
              name="title"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Payment request Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="template_type"
                value={inputs.template_type}
                disabled={Boolean(inputs.payment_template)}
                label="Payment Request Type"
                onChange={handleChange}
                required
              >
                <MenuItem value="onetime">One Time</MenuItem>
                <MenuItem value="recurring">Recurring</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Total Amount"
              variant="outlined"
              fullWidth
              type="number"
              disabled={Boolean(inputs.payment_template)}
              required
              name="total_amount"
              value={inputs.total_amount}
              onChange={handleChange}
            />
          </div>
          {inputs.template_type === "recurring" && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Initial Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  name="initial_amount"
                  value={inputs.initial_amount}
                  disabled={Boolean(inputs.payment_template)}
                  onChange={handleChange}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="No. Of Installments"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  name="no_of_installment"
                  value={inputs.no_of_installment}
                  disabled={Boolean(inputs.payment_template)}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Installment Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  aria-readonly
                  name="installment_amount"
                  value={installmentAmount}
                  disabled={Boolean(inputs.payment_template)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label1">
                    Plan Payment Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label1"
                    id="demo-simple-select"
                    name="request_iteration_type"
                    disabled={Boolean(inputs.payment_template)}
                    value={
                      inputs.request_iteration_type
                        ? inputs.request_iteration_type
                        : "week"
                    }
                    label="Plan Payment Type"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="month">Monthly</MenuItem>
                    <MenuItem value="week">Weekly</MenuItem>
                    <MenuItem value="year">Yearly</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.request_iteration_type == "custom" && (
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="No of days"
                    variant="outlined"
                    fullWidth
                    required
                    type="number"
                    disabled={Boolean(inputs.payment_template)}
                    name="number_of_days"
                    value={inputs.number_of_days}
                    onChange={handleChange}
                  />
                </div>
              )}
            </>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Vat Number"
              variant="outlined"
              fullWidth
              disabled={Boolean(inputs.payment_template)}
              name="vat_number"
              value={inputs.vat_number}
              onChange={handleChange}
            />
          </div>

          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Transaction Note"
                multiline
                rows={6}
                name="transaction_note"
                value={inputs.transaction_note}
                onChange={handleChange}
              />
            </FormControl>
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button" disabled={isLoading}>
              {request_slug ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
