import { Icon } from "@iconify/react";
import {
  Avatar,
  Badge,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CodeIcon from "@mui/icons-material/Code";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { filter } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { s3baseUrl } from "src/config/config";
import { get_root_value } from "src/utils/domUtils";
import {
  decode_markdown,
  get_date_with_user_time_zone,
  get_short_string,
} from "src/utils/constants";
import RefreshIcon from "@mui/icons-material/Refresh";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import { useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { live_events_list_api_chat } from "src/DAL/LiveEvents/LiveEvents";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import CustomDrawer from "src/components/CustomDrawer/CustomDrawer";
import NewChatUsers from "./NewChatUsers";
import MicIcon from "@mui/icons-material/Mic";
import { _get_chat_detail } from "src/DAL/Chat/Chat";
import { useSnackbar } from "notistack";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

export default function ChatList(props) {
  const [drawerState, setDrawerState] = useState(false);
  const { userInfo } = useContentSetting();
  const observer = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const chat_id = new URLSearchParams(location.search).get("chat_id");
  const [eventsList, setEventsList] = useState([]);
  const [state, setState] = useState({
    search_text: "",
    event_id: "none",
    chat_type: "all",
  });

  const { is_chat_allowed } = props;

  const {
    chatList,
    handleAddChat,
    handleSetSelectedChat,
    handleCloseChat,
    selectedChatUser,
    get_chat_history_list,
    setwhichChatListApiCall,
    setEventApiObject,
    isLoadingMoreChats,
    loadMoreChatPath,
    setIsLoadingMoreChats,
    totalPagesChats,
    pageNumberChat,
    get_chat_list,
    isLoadingChats,
    unreadChatCounts,
  } = useChat();

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const handleChatClick = (chat) => {
    if (state.event_id !== "" || state.event_id !== "none") {
      setwhichChatListApiCall(true);
    }
    navigate(`/chat`);
    handleSetSelectedChat(chat);
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMoreChats) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPagesChats > pageNumberChat) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [
      isLoadingMoreChats,
      totalPagesChats,
      pageNumberChat,
      loadMoreChatPath,
      observer,
    ]
  );

  const getLiveEventsList = async () => {
    const result = await live_events_list_api_chat();
    if (result.code === 200) {
      setEventsList(result.member_dynamite_event);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_profile_from_member = (member_id, members = []) => {
    const member_profile = members.find(
      (member) => member._id._id === member_id
    );
    return member_profile;
  };

  const get_receiver_profile = (members = []) => {
    const profile = members.find((m) => m._id?._id !== userInfo._id);
    return { ...profile, ...profile._id };
  };

  const get_chat_detail = async (chat_id) => {
    const result = await _get_chat_detail(chat_id);
    const profile = get_receiver_profile(result.chat.member);
    const my_profile = get_profile_from_member(
      userInfo._id,
      result.chat.member
    );
    if (result.code === 200) {
      handleSetSelectedChat({
        ...result.chat,
        receiver_profile: profile,
        my_profile: my_profile,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handlChangeTab = (tab) => {
    handleCloseChat();
    setState(() => {
      return {
        ...state,
        chat_type: tab,
      };
    });
  };

  useEffect(() => {
    let postObj = {
      ...state,
      event_id: state.event_id == "none" ? "" : state.event_id,
    };
    setEventApiObject(postObj);
    get_chat_list(postObj);
  }, [state]);

  useEffect(() => {
    getLiveEventsList();
  }, []);

  useEffect(() => {
    if (chat_id) {
      if (chatList.length > 0) {
        let find_chat = chatList.find((chat) => chat._id == chat_id);
        if (find_chat) {
          handleSetSelectedChat(find_chat);
        }
      } else {
        get_chat_detail(chat_id);
      }
    }
  }, [chat_id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const loadMoreData = () => {
    setIsLoadingMoreChats(true);
    let postObj = {
      ...state,
      event_id: state.event_id == "none" ? "" : state.event_id,
    };
    get_chat_history_list(postObj, loadMoreChatPath, chatList);
  };

  return (
    <>
      <div className="col-12 col-md-4 chat-left-part">
        {/* {unreadChatCounts > 0 && (
          <div className="d-flex justify-content-between mb-3">
            <div className="">Unread Chats Count</div>
            <div className="unread_chat_counts">
              <Badge badgeContent={unreadChatCounts} color="error"></Badge>
            </div>
          </div>
        )} */}
        <div
          className="chat-left-header"
          style={{ height: is_chat_allowed ? "140px" : "50px" }}
        >
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex">
              <div
                className="text-center me-2"
                onClick={() => {
                  handlChangeTab("all");
                }}
              >
                <span
                  className={`all-messages ${
                    state.chat_type == "all" ? "selected-messages" : ""
                  }`}
                >
                  All
                </span>
              </div>
              <div
                className="text-center"
                onClick={() => {
                  handlChangeTab("unread");
                }}
              >
                <span
                  className={`all-messages ${
                    state.chat_type == "unread" ? "selected-messages" : ""
                  }`}
                >
                  Unread
                </span>
              </div>
            </div>

            <div>
              <Tooltip title="Refresh Chat List" placement="top">
                <RefreshIcon
                  className="refresh-icon pointer me-2"
                  onClick={() => {
                    let postObj = {
                      ...state,
                      event_id: state.event_id == "none" ? "" : state.event_id,
                    };
                    get_chat_list(postObj);
                    handleSetSelectedChat(null);
                  }}
                />{" "}
              </Tooltip>
            </div>
          </div>
          {is_chat_allowed && (
            <div className="d-flex justify-content-between mt-3">
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  Portals
                </InputLabel>
                <Select
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.event_id}
                  fullWidth
                  onChange={handleChange}
                  label="Portals"
                  name="event_id"
                  className="svg-color"
                >
                  <MenuItem value="none">
                    <em>None</em>
                  </MenuItem>
                  {eventsList.map((event) => (
                    <MenuItem value={event._id} key={event._id}>
                      {event.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          <div className="chat-search-box mt-3">
            <Icon
              fontSize={20}
              className="chat-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="chat-search-input"
              type="text"
              placeholder="Search"
              value={state.search_text}
              name="search_text"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="position-relative">
          <div className="chat-list">
            {isLoadingChats ? (
              <div className="centered-chat-loader">
                <CircularProgress
                  className="mt-3 "
                  style={{ width: 30, height: 30 }}
                  color="primary"
                />
              </div>
            ) : chatList.length > 0 ? (
              <>
                {chatList?.map((chat, i) => {
                  return (
                    <div
                      onClick={() => handleChatClick(chat)}
                      className={
                        chat.receiver_profile._id === selectedChatUser?._id
                          ? "chat-list-user selected-chat d-flex p-2"
                          : "chat-list-user hover-effect d-flex p-2"
                      }
                      key={i}
                    >
                      <div>
                        <div className="user-profile">
                          <div className="user-level"></div>
                          <Avatar
                            src={
                              s3baseUrl + chat.receiver_profile.profile_image
                            }
                            style={{ height: 50, width: 50 }}
                          ></Avatar>
                          <div
                            className={
                              chat.receiver_profile.is_online
                                ? "online"
                                : "offline"
                            }
                          ></div>
                        </div>
                      </div>
                      <div className="chat-profile-data">
                        <div className="chat-profile-name ps-2 text-capitalize chat-profile-name-padding">
                          {chat.receiver_profile.first_name +
                            " " +
                            chat.receiver_profile.last_name}
                        </div>
                        <div className="chat-last-message ps-2 d-flex">
                          {chat.last_message_sender == userInfo._id && (
                            <div className="ticket-icon me-1">
                              {chat.last_message_status == "read" ? (
                                <Tooltip title="Seen">
                                  <Icon
                                    className="message-seen"
                                    fontSize={16}
                                    icon="mdi:tick-all"
                                  />
                                </Tooltip>
                              ) : chat.last_message_status == "delivered" ? (
                                <Tooltip title="Delivered">
                                  <Icon
                                    className=""
                                    fontSize={16}
                                    icon="mdi:tick-all"
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Sent">
                                  <Icon
                                    className=""
                                    fontSize={16}
                                    icon="mdi:tick"
                                  />
                                </Tooltip>
                              )}
                            </div>
                          )}
                          {chat?.message_type == "image" ? (
                            <>
                              <PhotoCameraIcon fontSize="10" /> Photo
                            </>
                          ) : chat?.message_type == "video" ? (
                            <>
                              <PlayCircleIcon fontSize="10" /> Video
                            </>
                          ) : chat?.message_type == "audio" ? (
                            <>
                              <MicIcon fontSize="18" /> Voice Message
                            </>
                          ) : chat?.message_type == "html" ? (
                            <>
                              <span className="new_message_text">
                                a new message sent
                              </span>
                            </>
                          ) : (
                            chat.last_message &&
                            get_short_string(
                              htmlDecode(decode_markdown(chat.last_message)),
                              25
                            )
                          )}
                        </div>
                      </div>
                      {chat.last_message_date_time && (
                        <div className="chat-last-time text-end ps-2">
                          <Tooltip
                            title={get_date_with_user_time_zone(
                              chat.last_message_date_time,
                              "DD MMM YYYY [at] hh:mm A",
                              userInfo.time_zone,
                              userInfo.adminTimeZone
                            )}
                            arrow
                          >
                            <span className="">
                              {get_date_with_user_time_zone(
                                chat.last_message_date_time,
                                "DD-MM-YYYY hh:mm A",
                                userInfo.time_zone,
                                userInfo.adminTimeZone
                              )}
                            </span>
                          </Tooltip>
                        </div>
                      )}
                      {chat?.my_profile?.unread_message_count > 0 && (
                        <div className="chat-unread-count">
                          {chat?.my_profile?.unread_message_count}
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <RecordNotFound title="Chats not found" />
            )}
            {totalPagesChats > pageNumberChat ? (
              <div className="col-12 text-center p-2">
                <span
                  ref={lastBookElementRef}
                  className=" mt-3"
                  onClick={loadMoreData}
                  id="load-more-feed"
                >
                  {isLoadingMoreChats ? "Loading..." : "Load More"}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>

          {is_chat_allowed && !drawerState && (
            <Tooltip title="New Message" placement="top">
              <button className="new-chat-button" onClick={handleOpenDrawer}>
                <AddIcon />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`New Message`}
        componentToPassDown={
          <NewChatUsers
            onCloseDrawer={handleCloseDrawer}
            handleAddChat={handleAddChat}
            eventsList={eventsList}
          />
        }
      />
    </>
  );
}
