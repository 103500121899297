import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import AddComment from "./AddComment";
import { projectMode, s3baseUrl } from "src/config/config";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import { useSnackbar } from "notistack";
import {
  community_feed_action_api,
  delete_feed_action,
  pin_feed_action,
} from "src/DAL/Community/Community";
import ShowAllLikes from "./ShowAllLikes";

import {
  COMMUNITY_LEVELS,
  count_chars,
  FEED_TXET_LIMIT,
  generateRandomID,
  get_short_string,
  post_description,
  replaceUserNamesWithHTML,
  string_avatar,
  TIME_ZONE_CONVERSION,
} from "src/utils/constants";
import { SingleComment } from "./components";
import { delete_comment_api } from "src/DAL/Community/Comments";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import ReactVideoPlayer from "src/components/ReactPlayers/ReactVideoPlayer";
import FeedImagesList from "./components/FeedImagesList";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import UserInfoCard from "./components/LikesPopup/UserInfoCard";
import ActionsCount from "./components/LikesPopup/ActionsCount";
import HandleAction from "./components/LikesPopup/HandleAction";
import { Icon } from "@iconify/react";
import EventInfoForPost from "./components/LikesPopup/EventInfoForPost";
import MentionedUserInfo from "./components/MentionedUserInfo";
import PollFeed from "./components/FeedPoll/PollFeed";

function PostCard({
  feeds_list,
  getFeedsListingOnDelete,
  handleShowDetails,
  handleSelectedImage,
  handleFeedEdit,
  handleINternalNote,
  handleINternalNoteClient,
  handleUpdateSpecificFeed,
  handleUpdateShowAll,
  feedSetting,
  list_type,
  event_id,
  handleUpdatePoll,
  handlePollDetail,
}) {
  const settings = useContentSetting();
  const { socket, userInfo, consultantInfo, adminTimeZone, isAccess } =
    settings;
  const targetRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const [showAddComment, setShowAddComment] = useState(false);
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [isLiked, setIsLiked] = useState(true);
  const [commetMessage, setCommetMessage] = useState({});
  const [topLikedUser, setTopLikedUser] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [isShowMore, setIsShowMore] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [pinLabel, setPinLabel] = useState("pin");
  const [openPin, setOpenPin] = useState(false);
  const [showGlow, setShowGlow] = useState(true);
  const [openDeleteComment, setOpenDeleteComment] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [userInfoBox, setUserInfoBox] = useState({});
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [formAction, setFormAction] = useState("ADD");
  const [selectedComment, setSelectedComment] = useState({});
  const { handleAddChat } = useChat();
  const live_event = window.location.pathname.includes("/live-events/");
  const my_event = window.location.pathname.includes("/my-events/");

  const post_description_id = "id" + generateRandomID(16);

  const get_start_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "DD-MM-YYYY hh:mm A",
      adminTimeZone,
      adminTimeZone
    );
  };

  const handleFeedAction = async (id, action) => {
    if (action === "feedlike") {
      setIsLiked(true);
    } else if (action === "feedunlike") {
      setIsLiked(false);
    }
    const formData = new FormData();
    formData.append("action", action);
    formData.append("feed", id);

    const result = await community_feed_action_api(formData);
    if (result.code === 200) {
      if (action === "feedlike" || action === "feedunlike") {
        // setTopLikedUser(result.action_response.top_liked_user);
        setLikeCount(result.action_response.like_count);
      }
      const socketData = {
        action,
        feed_id: id,
        token: localStorage.getItem("token"),
        creator_id: result.action_response.creator_id,
        action_by: result.action_response.sender,
        action_response: result.action_response,
      };
      socket.emit("feed_room_action_event", socketData);
    } else {
      // if (action === "feedlike") {
      //   setIsLiked(false);
      // } else if (action === "feedunlike") {
      //   setIsLiked(true);
      // }
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleAgree = async () => {
    setOpenDelete(false);
    const result = await delete_feed_action(feeds_list._id);
    if (result.code === 200) {
      enqueueSnackbar("Post deleted successfully", { variant: "success" });
      getFeedsListingOnDelete(feeds_list._id);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handlePinFeed = async () => {
    setOpenPin(false);
    const formData = new FormData();
    formData.append("feed", feeds_list._id);
    if (
      feeds_list.is_feature === false ||
      feeds_list.is_feature === "false" ||
      feeds_list.is_feature === undefined
    ) {
      formData.append("action", "feature");
    } else {
      formData.append("action", "unfeature");
    }
    const result = await pin_feed_action(formData);
    if (result.code === 200) {
      handleUpdateSpecificFeed(feeds_list._id);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handlePostDetail = (post_id) => {
    handleShowDetails(feeds_list);
  };

  const handleClickImage = (event, index, path) => {
    handleSelectedImage(feeds_list, index);
  };

  const handleViewAllComments = (value) => {
    handleUpdateShowAll(feeds_list._id, value);
  };

  const handleOpenLikedBox = () => {
    setOpenLikesModal(true);
  };

  const handleCloseSimpleBox = () => {
    setOpenLikesModal(false);
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
  };

  const handleAgreePin = (value) => {
    setPinLabel(value ? "Unpin" : "Pin");
    setOpenPin(true);
  };

  const handleSendMessage = async (row) => {
    handleAddChat(row.action_info?.action_id);
  };

  let win_status = feeds_list.feed_appear_by;
  let feed_level = feeds_list.created_for_level_or_type;
  let find_level = COMMUNITY_LEVELS.find((level) => level.name == feed_level);

  // if (feed_level !== "delegate") {
  //   // in case of both
  //   feed_level = "consultant";
  // }

  const handleCommentEdit = (selected_comment) => {
    setSelectedComment(selected_comment);
    setFormAction("EDIT");
    setShowAddComment(true);
  };

  const handleCommentDelete = (selected_comment) => {
    setOpenDeleteComment(true);
    setSelectedComment(selected_comment);
  };

  //Deleting Category
  const handleDelete = async () => {
    setOpenDeleteComment(false);
    const result = await delete_comment_api(selectedComment._id);
    if (result.code === 200) {
      // const socketData = {
      //   action: "delete_comment",
      //   feed_id: selectedComment.feeds,
      //   comment: selectedComment._id,
      //   token: localStorage.getItem("token"),
      //   action_response: result.action_response,
      //   creator_id: result.action_response.creator_id,
      // };
      // socket.emit("feed_room_action_event", socketData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = [];
    console.log(
      isAccess.edit_delete_option_in_source_all_source_feeds,
      feeds_list.is_self,
      feeds_list.poll_info.poll_status,
      "main check"
    );

    if (
      feeds_list.is_self ||
      (isAccess.edit_delete_option_in_source_all_source_feeds &&
        list_type !== "the_cosmos")
    )
      if (feeds_list.feed_type === "poll" && feeds_list.is_self) {
        if (feeds_list.poll_info.poll_status !== "expired") {
          MENU_OPTIONS.push({
            label: "Edit",
            icon: "akar-icons:edit",
            handleClick: handleFeedEdit,
          });
        }
      } else {
        MENU_OPTIONS.push({
          label: "Edit",
          icon: "akar-icons:edit",
          handleClick: handleFeedEdit,
        });
      }

    {
      MENU_OPTIONS.push({
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      });
    }

    // if (userInfo.is_super_delegate) {
    if (isAccess.feed_pin_unpin_option) {
      MENU_OPTIONS.push({
        label: feeds_list.is_feature == false ? "Pin" : "Unpin",
        icon: "akar-icons:pin",
        handleClick: () => handleAgreePin(feeds_list.is_feature),
      });
    }

    if (
      userInfo.is_super_delegate &&
      isAccess.is_chat_allowed &&
      row.action_info.action_id !== userInfo._id
    ) {
      MENU_OPTIONS.push({
        label: "Message",
        icon: "material-symbols:send",
        handleClick: handleSendMessage,
      });
    }
    if (live_event || my_event) {
      MENU_OPTIONS.push({
        label: "Add As Personel Notes",
        icon: "akar-icons:edit",
        handleClick: handleINternalNote,
      });
    }

    return MENU_OPTIONS;
  };
  const handleMenuClient = (row) => {
    let MENU_OPTIONS_CLIENT = [];
    if (live_event || my_event) {
      MENU_OPTIONS_CLIENT.push({
        label: "Add As Personel Notes",
        icon: "akar-icons:edit",
        handleClick:
          row.action_info?.action_by == "member_user"
            ? handleINternalNoteClient
            : handleINternalNote,
      });
    }
    return MENU_OPTIONS_CLIENT;
  };

  useEffect(() => {
    setIsLiked(feeds_list.is_liked);
    setCommetMessage(feeds_list.comment);
    // setTopLikedUser(feeds_list.top_liked_user);
    setLikeCount(feeds_list.like_count);
    setCommentCount(feeds_list.comment_count);

    const options = {
      root: null, // use the viewport as the root
      rootMargin: "0px",
      threshold: 0.5, // trigger when 50% of the target is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setShowGlow(entry.isIntersecting);
        if (entry.isIntersecting) {
          setTimeout(() => {
            setShowGlow(false); // Hide the image after 3 seconds
          }, 3000);
        }
      });
    }, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [feeds_list, feeds_list.comment]);

  useEffect(() => {
    const handleClickInside = (event) => {
      if (event.target.classList?.contains("mentioned-name")) {
        const targetElement = event.target;
        const mentioned = document.getElementById(post_description_id);
        if (targetElement && mentioned) {
          const rect = targetElement.getBoundingClientRect();
          const mentionedRect = mentioned.getBoundingClientRect();
          const left = rect.left - mentionedRect.left;
          const top = rect.bottom - mentionedRect.top;
          setPosition({ left, top });
          setTimeout(() => {
            setShowInfoBox(true);
          }, 100);
        }
      } else {
        if (
          event.target.classList?.contains("mentioned-user-info") ||
          event.target.closest(".mentioned-user-info")
        ) {
          setShowInfoBox(true);
        } else {
          setShowInfoBox(false);
        }
      }
    };
    document.addEventListener("mouseover", handleClickInside);
    return () => {
      document.removeEventListener("mouseover", handleClickInside);
    };
  }, [feeds_list, post_description_id]);

  return (
    <div
      className={`profile-cards p-3 mb-3 main-section ${
        feeds_list.is_reward_feed ? "glow-reward-feed" : ""
      }`}
      ref={targetRef}
    >
      {feeds_list.is_reward_feed &&
        showGlow &&
        feeds_list.reward_data?.reward_feed_gif && (
          <div className="gif-image">
            <img src={s3baseUrl + feeds_list.reward_data?.reward_feed_gif} />
          </div>
        )}
      <div className="d-flex w-100 justify-content-between">
        <UserInfoCard
          profile_image={s3baseUrl + feeds_list.action_info?.profile_image}
          user_name={htmlDecode(feeds_list.action_info?.name)}
          avatar_char={string_avatar(feeds_list.action_info?.name)}
          date={feeds_list?.createdAt}
          activity_type={null}
        />
        <div className="poster-logo text-end">
          {list_type == "event" ? (
            <span className="d-flex feed-level">
              {(!feeds_list.is_publish || feeds_list.is_publish == "false") && (
                <Tooltip
                  title={`post will be published on ${get_start_end_time(
                    feeds_list.schedule_date_time
                  )} (Europe/Dublin)`}
                >
                  <UpdateIcon />
                </Tooltip>
              )}
              {feeds_list.is_self && handleMenu(feeds_list).length > 0 ? (
                <CustomPopover
                  menu={handleMenu(feeds_list)}
                  data={feeds_list}
                />
              ) : live_event || my_event ? (
                <CustomPopover
                  menu={handleMenuClient(feeds_list)}
                  data={feeds_list}
                />
              ) : (
                ""
              )}
            </span>
          ) : (
            <span className="d-flex feed-level">
              {(!feeds_list.is_publish || feeds_list.is_publish == "false") && (
                <Tooltip
                  title={`post will be published on ${get_start_end_time(
                    feeds_list.schedule_date_time
                  )} (Europe/Dublin)`}
                >
                  <UpdateIcon />
                </Tooltip>
              )}

              {find_level ? (
                <Tooltip
                  title={
                    feedSetting[`${find_level?.name}_title`]
                      ? feedSetting[`${find_level?.name}_title`]
                      : find_level?.title
                  }
                >
                  <img
                    src={
                      feedSetting[`${find_level?.name}_badge`]
                        ? s3baseUrl + feedSetting[`${find_level?.name}_badge`]
                        : ""
                    }
                    alt={feedSetting[`${find_level?.name}_title`]}
                    className="dynamite-level-image"
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    consultantInfo[`${feed_level}_feed_label`]
                      ? consultantInfo[`${feed_level}_feed_label`]
                      : feed_level
                  }
                >
                  <img
                    src={
                      consultantInfo[`${feed_level}_feed_icon`]
                        ? s3baseUrl + consultantInfo[`${feed_level}_feed_icon`]
                        : ""
                    }
                    className="dynamite-level-image"
                  />
                </Tooltip>
              )}

              {win_status == "win" && feedSetting?.win_badge && (
                <Tooltip
                  title={feedSetting.win_title ? feedSetting?.win_title : "Win"}
                >
                  <img
                    src={
                      feedSetting?.win_badge
                        ? s3baseUrl + feedSetting?.win_badge
                        : ""
                    }
                    className="dynamite-level-image"
                  />
                </Tooltip>
              )}

              {list_type == "the_cosmos" ? (
                feeds_list.is_self && (
                  <CustomPopover
                    menu={handleMenu(feeds_list)}
                    data={feeds_list}
                  />
                )
              ) : (
                /* userInfo.is_super_delegate */
                <CustomPopover
                  menu={handleMenu(feeds_list)}
                  data={feeds_list}
                />
              )}
            </span>
          )}
        </div>
      </div>
      <div
        className="mt-2 post-description position-relative"
        id={post_description_id}
      >
        {/* {showInfoBox && <MentionedUserInfo position={position} />} */}
        <p
          onClick={() => handlePostDetail(feeds_list._id)}
          dangerouslySetInnerHTML={{
            __html: urlify(
              count_chars(feeds_list.description) > FEED_TXET_LIMIT &&
                isShowMore
                ? post_description(
                    replaceUserNamesWithHTML(
                      feeds_list.description,
                      feeds_list.mentioned_users
                    ),
                    FEED_TXET_LIMIT
                  )
                : replaceUserNamesWithHTML(
                    feeds_list.description,
                    feeds_list.mentioned_users
                  )
            ),
          }}
        ></p>
        {count_chars(feeds_list.description) > FEED_TXET_LIMIT && (
          <span
            className="ms-2"
            onClick={() => {
              setIsShowMore(!isShowMore);
            }}
          >
            {isShowMore ? "See More" : "See Less"}
          </span>
        )}
      </div>
      {feeds_list.feed_type === "poll" && feeds_list.poll_info && (
        <PollFeed
          feed={feeds_list}
          handleUpdateFeed={handleUpdatePoll}
          handlePollDetail={handlePollDetail}
          feedSetting={feedSetting}
        />
      )}
      {feeds_list.feed_type === "image" &&
        feeds_list.feed_images &&
        feeds_list.feed_images.length > 0 && (
          <div className="feed-image">
            <FeedImagesList
              feed_images={feeds_list.feed_images}
              handleClickImage={handleClickImage}
            />
          </div>
        )}

      {feeds_list.feed_type === "live" &&
        (projectMode === "DEV" ? (
          <div className="feed-image position-relative">
            <div className="live-stream-label">
              <Icon
                className="me-1"
                fontSize={16}
                style={{ color: feeds_list.is_live_streaming ? "red" : "grey" }}
                icon="carbon:dot-mark"
              />
              {feeds_list.is_live_streaming ? (
                <span>Live</span>
              ) : (
                <span>Offline</span>
              )}
            </div>
            {feeds_list.image && feeds_list?.image?.thumbnail_1 && (
              <img
                src={s3baseUrl + feeds_list?.image?.thumbnail_1}
                onClick={() => handlePostDetail(feeds_list._id)}
              />
            )}
          </div>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: feeds_list.embed_code,
            }}
          ></div>
        ))}
      {feeds_list.feed_type === "embed_code" && (
        <div
          dangerouslySetInnerHTML={{
            __html: feeds_list.embed_code,
          }}
          className="feed-image"
        ></div>
      )}

      {feeds_list.feed_type === "video" && feeds_list.video_url && (
        <div className="feed-image">
          <ReactVideoPlayer url={feeds_list.video_url} />
        </div>
      )}

      {feeds_list.event_info?.is_event_info && (
        <EventInfoForPost feed={feeds_list} />
      )}

      {feeds_list.is_publish && (
        <>
          <ActionsCount
            handleOpenLikedBox={handleOpenLikedBox}
            likeCount={likeCount}
            topLikedUser={topLikedUser}
            commentsCount={feeds_list.comment_count}
            handleClick={() => handlePostDetail(feeds_list._id)}
          />
          <hr className="mt-0" />
          <HandleAction
            isLiked={isLiked}
            handleFeedAction={handleFeedAction}
            setShowAddComment={setShowAddComment}
            feed_id={feeds_list._id}
          />
          <hr />
        </>
      )}
      <div className="profile-comments">
        {feeds_list.comment?.length > 0 &&
          feeds_list.comment.map((comment) => {
            return (
              <SingleComment
                comment={comment}
                handleCommentEdit={handleCommentEdit}
                handleCommentDelete={handleCommentDelete}
                handleUpdateSpecificFeed={handleUpdateSpecificFeed}
                list_type={list_type}
                feed={feeds_list}
                event_id={event_id}
                parent_comment={comment}
              />
            );
          })}
        {/* {commentCount > 1 ? (
          <div className="d-flex justify-content-between">
            <div className="view-comment mt-2 me-3 date-color">
              {!feeds_list.is_show_all ? (
                <span onClick={() => handleViewAllComments(true)}>
                  View more comments
                </span>
              ) : (
                <span onClick={() => handleViewAllComments(false)}>
                  View less comments
                </span>
              )}
            </div>
            <div className="view-comment mt-2 me-3 date-color">
              {totalCommentCount} of {commentCount}
            </div>
          </div>
        ) : (
          ""
        )} */}
        {showAddComment && !feeds_list.is_show_all && (
          <AddComment
            post_id={feeds_list._id}
            formAction={formAction}
            feedsData={selectedComment}
            setFormAction={setFormAction}
            setShowAddComment={setShowAddComment}
            handleUpdateSpecificFeed={handleUpdateSpecificFeed}
            feed={feeds_list}
            list_type={list_type}
            event_id={event_id}
          />
        )}
      </div>
      {openLikesModal === true && (
        <ShowAllLikes
          openLikesModal={openLikesModal}
          handleCloseSimpleBox={handleCloseSimpleBox}
          post_id={feeds_list._id}
        />
      )}
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this post?"}
        handleAgree={handleAgree}
      />
      <CustomConfirmation
        open={openDeleteComment}
        setOpen={setOpenDeleteComment}
        title={"Are you sure you want to delete this comment?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openPin}
        setOpen={setOpenPin}
        title={`Are you sure you want to ${pinLabel} this post?`}
        handleAgree={handlePinFeed}
      />
    </div>
  );
}

export default PostCard;
