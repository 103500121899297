import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
const ScheduleMessage = ({
  setTimePop,
  setInputs,
  inputs,
  handleSendSchedule,
  setAudioScadule,
  isLoading,
  isAudio,
}) => {
  const initialTime = new Date();
  initialTime.setHours(0, 0, 0, 0);

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeDates = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value.$d }));
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mt-3">
          <FormControl fullWidth required size="small">
            <InputLabel id="demo-simple-select-label">
              Message Schedule
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="messageSchedule"
              value={inputs.messageSchedule}
              label="Message Schedule"
              onChange={(event) =>
                handleChangeOthers("messageSchedule", event.target.value)
              }
            >
              <MenuItem value="publish">Send Now</MenuItem>
              <MenuItem value="schedule">Schedule</MenuItem>
            </Select>
          </FormControl>
        </div>
        {inputs.messageSchedule == "schedule" && (
          <>
            <div className="col-6 mt-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Publish Date(Europe/Dublin)"
                  name="start_date"
                  inputFormat="DD-MM-YYYY"
                  value={inputs.date}
                  minDate={new Date()}
                  format="DD-MM-YYYY"
                  InputLabelProps={{
                    shrink: true,
                    variant: "standard",
                    style: {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "10ch",
                    },
                  }}
                  onChange={(e) => handleChangeDates("date", e)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} required={true} />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-6 mt-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Publish Time(Europe/Dublin)"
                  value={inputs.time}
                  onChange={(e) => handleChangeDates("time", e)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} required={true} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </>
        )}

        <div class="col-12 mt-3 text-end">
          <button
            class="small-contained-button "
            onClick={() =>
              isAudio ? setAudioScadule() : handleSendSchedule("", "", "")
            }
          >
            {isLoading ? "please wait ..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleMessage;
