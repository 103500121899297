import { Icon } from "@iconify/react";
import { Divider } from "@mui/material";
import React from "react";

function Participants({ participants }) {
  console.log(participants, "participants");
  return (
    <div className="live-stream-participants">
      {participants.map((participant) => (
        <>
          <div className="p-2 d-flex justify-content-between">
            <div>
              {participant.displayName} {participant.isHost ? "(Host)" : ""}
            </div>
            <div className="d-flex">
              <div className="me-2">
                {participant.muted ? (
                  <Icon
                    className="participant-control-icon"
                    icon="material-symbols:mic-off"
                  />
                ) : (
                  <Icon
                    className="participant-control-icon"
                    icon="ic:outline-mic"
                  />
                )}
              </div>

              <div>
                {participant.bVideoOn ? (
                  <Icon
                    className="participant-control-icon"
                    icon="iconoir:video-camera"
                  />
                ) : (
                  <Icon
                    className="participant-control-icon"
                    icon="iconoir:video-camera-off"
                  />
                )}
              </div>
            </div>
          </div>
          <Divider></Divider>
        </>
      ))}
    </div>
  );
}

export default Participants;
