import { invokeApi } from "../../bl_libs/invokeApi";

export const departmentListAPI = async (data) => {
  const requestObj = {
    path: `api/department/list_active_department`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _internal_note = async (ticketId) => {
  const requestObj = {
    path: `api/support_ticket/list_intenal_note/${ticketId}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _leads_comment = async (ticketId) => {
  const requestObj = {
    path: `api/sales_section/lead/get_comment/${ticketId}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const NewdepartmentListAPI = async (data) => {
  const requestObj = {
    path: `api/department/list_active_department?type=delegate`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const AddSupportTicketAPI = async (data) => {
  const requestObj = {
    path: `api/support_ticket/add_support_ticket/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const ticketsListAPI = async (page, limit, data) => {
  const requestObj = {
    path: `api/support_ticket/get_filtered_list_support_ticket_by_consultant?page=${page}&limit=${limit}&search_text=`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_list_support_ticket_with_pagination = async (
  data,
  page_number,
  limit,
  search
) => {
  const requestObj = {
    path: `api/support_ticket/get_filtered_list_support_ticket_by_consultant?page=${page_number}&limit=${limit}&search_text=${
      search ? search : ""
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const departmentListAPIs = async (data) => {
  const requestObj = {
    path: `api/department/list_active_department?type=delegate`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const departmentListNewAPIs = async (data) => {
  const requestObj = {
    path: `api/department/list_department/client/tickets`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_list_support_ticket_with_pagination_new = async (
  valueData,
  page_number,
  limit,
  filterName
) => {
  const requestObj = {
    path: `api/support_ticket/get_filtered_list_support_ticket_by_consultant_v3?page=${page_number}&limit=${limit}&search_text=${
      filterName ? filterName : ""
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: {
      filter_by: valueData,
    },
  };
  return invokeApi(requestObj);
};
export const deleteSupportTicketApi = async (id) => {
  const requestObj = {
    path: `api/support_ticket/delete_support_ticket/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_internal_id = async (ticketId, id) => {
  const requestObj = {
    path: `api/support_ticket/delete_intenal_note/${ticketId}/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_lead_comment = async (ticketId, id) => {
  const requestObj = {
    path: `api/sales_section/lead/delete_comment/${ticketId}/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const UploadSupportTicketImageOnS3 = async (data) => {
  const requestObj = {
    path: `api/support_ticket/upload_support_ticket_comment_images`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const SupportTicketDetailApi = async (ticket_id) => {
  const requestObj = {
    path: `api/support_ticket/detail_support_ticket/${ticket_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const markResolved = async (ticket_id) => {
  const requestObj = {
    path: `api/support_ticket/close_support_ticket/${ticket_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteSupportTicket = async (ticket_id) => {
  const requestObj = {
    path: `api/support_ticket/trash_support_ticket/${ticket_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const DeleteSupportTicketImageOnS3 = async (data) => {
  const requestObj = {
    path: `api/support_ticket/delete_support_ticket_images_from_s3/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _move_need_fixes = async (data) => {
  const requestObj = {
    path: `api/support_ticket/move_support_ticket_status`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _move_need_attention = async (ticket_id, status_to_move) => {
  const requestObj = {
    path: `api/support_ticket/move_support_ticket_status`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: {
      support_ticket: ticket_id,
      status_to_move: "needs_to_attention",
    },
  };
  return invokeApi(requestObj);
};
export const _revert_back = async (ticket_id, status_to_move) => {
  const requestObj = {
    path: `api/support_ticket/move_support_ticket_status`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: {
      support_ticket: ticket_id,
      status_to_move: "revert_back",
    },
  };
  return invokeApi(requestObj);
};
export const _move_reminder = async (postData) => {
  const requestObj = {
    path: `api/support_ticket/move_support_ticket_status`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const _send_reminder = async (data) => {
  const requestObj = {
    path: `api/support_ticket/send_reminder`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _add_internal_note = async (data) => {
  const requestObj = {
    path: `api/support_ticket/add_intenal_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _add_comment = async (data) => {
  const requestObj = {
    path: `api/sales_section/lead/comment`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_internal_note = async (data, id) => {
  const requestObj = {
    path: `api/support_ticket/edit_intenal_note`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_lead_comment = async (data, id) => {
  const requestObj = {
    path: `api/sales_section/lead/edit_comment`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const resolve = async (data) => {
  const requestObj = {
    path: `api/support_ticket/mark_resolve`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UpdateSupportTicketAPI = async (ticket_id, data) => {
  const requestObj = {
    path: `api/support_ticket/edit_support_ticket/${ticket_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _change_department_api = async (ticket, postData) => {
  const requestObj = {
    path: `api/support_ticket/edit_department_by_delegate/${ticket}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
