import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import ReactApexChart from "react-apexcharts";

import {
  delete_event_api,
  eventsListing,
  eventsListingWithPagination,
} from "src/DAL/Calendar/Calendar";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "group",
    label: "Groups",
    alignRight: false,
    renderData: (row) => {
      return (
        <div>
          {row.group.map((group) => {
            return (
              <>{group._id ? <p className="mb-0">{group._id.title} </p> : ""}</>
            );
          })}
        </div>
      );
    },
  },
  {
    id: "recurring_type",
    label: "Event Type",
    alignRight: false,
    className: "text-capitalize",
  },
  { id: "start_date", label: "Start Date", alignRight: false },
  { id: "end_date", label: "End Date", alignRight: false },
  // { id: "member_count", label: "Members", alignRight: false },
  {
    id: "status",
    label: "Status",
    type: "row_status",
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CalendarEventsList() {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Net Profit",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Free Cash Flow",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
      {
        name: "Free Cash Flow other",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      colors: ["#FF5733", "#33FF57", "#3357FF", "#395213"],
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 10, // Set the borderRadius to round the bars
        borderRadiusApplication: "end", // Round the bars at the top
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
        labels: {
          style: {
            colors: "black",
          },
        },
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
        labels: {
          style: {
            colors: "black",
          },
        },
        axisBorder: {
          show: true,
          color: "black",
        },
      },
      fill: {
        opacity: 1,
      },
    },
  });
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDoc, setSelectedDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [eventsData, setEventsData] = useState([]);

  const [categoriesData, setCategoriesData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };
  const searchFunction = (e) => {
    if (e) {
      e.preventDefault();
    }
    setPage(0);
    setPageCount(1);
    localStorage.setItem("events_search", searchText);

    getEventsListing(searchText);
  };

  const getEventsListing = async (text) => {
    setIsLoading(true);
    const result = await eventsListingWithPagination(page, rowsPerPage, text);
    if (result.code == 200) {
      const events = result.event.map((event) => {
        return {
          ...event,
          // member_count: event.member.length,
        };
      });
      setTotalPages(result.total_pages);
      setTotalCount(result.total_count);
      setEventsData(events);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleOpenDrawer = () => {
    navigate(`/calendar-events/add-event`);
  };

  const handleAgreeDelete = (value) => {
    setSelectedDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_event_api(selectedDoc.event_slug);
    if (result.code === 200) {
      setEventsData((eventsData) => {
        return eventsData.filter((data) => data._id !== selectedDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(`/calendar-events/edit-event/${value.event_slug}`);
  };

  const handleNavigate = () => {
    navigate(`/calendar-events`);
  };

  useEffect(() => {
    let search = localStorage.getItem("events_search");
    if (search) {
      setSearchText(search);
    }
    getEventsListing(search);
  }, [page, rowsPerPage]);

  const handleDetailPage = (value) => {
    navigate(`${`/calendar-events/event-detail/${value.event_slug}`}`, {
      state: { editValues: value },
    });
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "eva:eye-fill",
      handleClick: handleDetailPage,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this event?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-12 text-start mb-4 d-flex">
            <span>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton>
            </span>
            <h2 className="ms-2">Events List</h2>
          </div>

          <div className="col-lg-4 col-sm-12 text-end">
            <button
              className="small-contained-button mt-1 mb-4 me-2"
              onClick={handleNavigate}
            >
              Show Calendar
            </button>
            <button
              className="small-contained-button float-end mt-1 mb-4"
              onClick={handleOpenDrawer}
            >
              Add Event
            </button>
          </div>
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={eventsData}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pagePagination={true}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
            />
          </div>
        </div>
      </div>
    </>
  );
}
