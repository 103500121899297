import React, { useEffect, useState } from "react";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { Deletebooking, bookingListApi } from "src/DAL/Booking/bookingApi";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import { BookingFilter } from "src/components/booking/BookingFilter";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import AddOrUpdateBooking from "src/components/booking/AddorUpdateBooking";
import PassBooking from "src/components/booking/PassBooking";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import ChangeBookingStatus from "src/components/booking/ChangeBookingStatus";
import { get_root_value } from "src/utils/domUtils";
import { show_proper_words } from "src/utils/constants";
import AddAsInternalNote from "../Community/AddAsInternalNote";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const get_booking_date = (booking) => {
  const dateTime = moment().format("YYYY-MM-DD") + " " + booking.time;
  let booking_date = `${moment.utc(booking.date).format("DD-MM-YYYY")} (${
    booking.time +
    " - " +
    moment(dateTime, "DD-MM-YYYY hh:mm A")
      .add(booking.slot_duration, "minutes")
      .format("hh:mm A")
  })`;
  return booking_date;
};

const get_remaining_hours = (booking) => {
  let new_Date = moment(booking.date).format("DD-MM-YYYY");
  const startDate = moment(
    //converts 1:20 AM to 01:20 AM
    new_Date + " " + booking.time,
    "DD-MM-YYYY hh:mm A"
  ).format("YYYY-MM-DD hh:mm A");
  const now = moment(new Date()).format("YYYY-MM-DD hh:mm A");
  const now_time = moment(now, "YYYY-MM-DD hh:mm A");
  const start_time = moment(startDate, "YYYY-MM-DD hh:mm A");
  const diffInHours = start_time.diff(now_time, "hours");
  return diffInHours;
};

export default function BookingList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [bookingsData, setBookingsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [passBookingDrawer, setPassBookingDrawer] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const EMPTY_FILTER = {
    start_date: null,
    end_date: null,
    booking_status: null,
    search_text: "",
    sort_by: "",
    filter_by_dates: false,
    sale_page: [],
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterDataUpdate, setFilterDataUpdate] = useState(EMPTY_FILTER);
  const [filterDrawerStare, setFilterDrawerState] = useState(false);
  const [bookingId, setBookingId] = useState();
  const [selectedBooking, setSelectedBooking] = useState({});

  const handleDetailPage = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `/booking/answers-detail?member_id=${value.user_info._id}&created_for_id=${value.page._id}&created_for=page`,
      {
        state: value,
      }
    );
  };

  const TABLE_HEAD = [
    {
      id: "number",
      label: "#",
      type: "number",
    },
    {
      id: "user_name",
      label: "Name",
      handleClick: handleDetailPage,
      className: "cursor-pointer",
    },
    {
      id: "email",
      label: "Email",
      handleClick: handleDetailPage,
      className: "cursor-pointer",
    },
    {
      id: "page_title",
      label: "Booking Page",
    },
    { id: "booking_date", label: "Date", alignRight: false },
    {
      id: "status",
      label: "Booking Status",
      renderData: (row) => {
        let find_status = row.booking_status_info;
        if (find_status) {
          return (
            <Tooltip title={find_status?.title}>
              <Chip
                label={find_status?.title}
                variant="contained"
                className="booking-status-chip"
                style={{
                  backgroundColor: find_status.background_color,
                  color: find_status.text_color,
                  height: "22px",
                }}
                size="small"
              />
            </Tooltip>
          );
        }
      },
    },
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
  ];

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const handleOpenEditDrawer = (value) => {
    setBookingId(value._id);
    setEditDrawerState(true);
  };

  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const handleOpenPassBookingDrawer = (value) => {
    setSelectedBooking(value);
    setPassBookingDrawer(true);
  };

  const handleClosePassBookingDrawer = () => {
    setPassBookingDrawer(false);
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleAgreeDelete = (value) => {
    setBookingId(value._id);
    setOpenDelete(true);
  };

  const handleChaneOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeStatus = (value) => {
    setSelectedBooking(value);
    setChangeStatus(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await Deletebooking(bookingId);

    if (result.code === 200) {
      let AfterDeleteArray = bookingsData.filter(
        (item) => item._id !== bookingId
      );
      setBookingsData(AfterDeleteArray);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const getBookingListing = async (data, value, array) => {
    setIsLoading(true);
    let postData = { ...data };
    let Sale_page = value == "sale_page_updates" ? array : postData.sale_page;
    if (Sale_page.length > 0) {
      postData.sale_page = Sale_page.map((sale_page) => sale_page._id);
    }
    if (value == "sort_by") {
      postData.sort_by = "";
    }
    if (value == "date") {
      postData.end_date = "";
      postData.start_date = "";
      postData.filter_by_dates = false;
    } else {
      if (postData.start_date) {
        postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
      }
      if (postData.end_date) {
        postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
      }
    }

    if (postData.booking_status) {
      postData.booking_status = postData.booking_status._id;
    }

    const result = await bookingListApi(page, rowsPerPage, postData);

    if (result.code == 200) {
      const bookings = result.bookings.map((booking) => {
        let page_title = booking?.page?.sale_page_title;
        return {
          ...booking,
          booking_date: get_booking_date(booking),
          user_name:
            booking.user_info.first_name + " " + booking.user_info.last_name,
          email: booking.user_info.email,
          page_title: page_title ? page_title : "N/A",

          MENU_OPTIONS: getMenuList(
            {
              ...booking,
              remaining_hours: get_remaining_hours(booking),
            },
            result.hours_for_pass_booking
          ),
        };
      });

      setBookingsData(bookings);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("booking_page_number", newPage);
  };

  const handlePersonelNotes = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/booking/notes/${value._id}`, {
      state: value,
    });
  };

  const getMenuList = (row, hours) => {
    const MENU_OPTIONS = [
      {
        label: "Question Answers Detail",
        icon: "gg:details-more",
        handleClick: handleDetailPage,
      },
      {
        label: "Booking Notes",
        icon: "gridicons:pages",
        handleClick: handlePersonelNotes,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      },
    ];

    if (row.status !== "cancelled" && row.status !== "complete") {
      MENU_OPTIONS.push({
        label: "Change Status",
        icon: "akar-icons:edit",
        handleClick: handleChangeStatus,
      });

      if (row.remaining_hours > hours - 1) {
        MENU_OPTIONS.push(
          {
            label: "Edit",
            icon: "akar-icons:edit",
            handleClick: handleOpenEditDrawer,
          },
          {
            label: "Pass Booking",
            icon: "akar-icons:edit",
            handleClick: handleOpenPassBookingDrawer,
          }
        );
      }
    }
    return MENU_OPTIONS;
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    setFilterDataUpdate(filterData);
    getBookingListing(filterData);
    localStorage.setItem("booking_filter_data", JSON.stringify(filterData));
    handleCloseFilterDrawer();
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("booking_filter_data");
    getBookingListing(EMPTY_FILTER);
    handleCloseFilterDrawer();
  };

  const handleDeleteProgramChips = (chipToDelete) => {
    setFilterData({
      ...filterData,
      ["sale_page"]: filterData.sale_page.filter(
        (chip) => chip._id !== chipToDelete
      ),
    });
    const UpdatedFilter = {
      ...filterData,
      ["sale_page"]: filterData.sale_page.filter(
        (chip) => chip._id !== chipToDelete
      ),
    };
    setFilterDataUpdate({
      ...filterDataUpdate,
      ["sale_page"]: filterDataUpdate.sale_page.filter(
        (chip) => chip._id !== chipToDelete
      ),
    });
    setPage(0);
    setPageCount(1);
    let Sale_page_array = filterData.sale_page.filter(
      (chip) => chip._id !== chipToDelete
    );
    getBookingListing(filterData, "sale_page_updates", Sale_page_array);
    localStorage.setItem("booking_filter_data", JSON.stringify(UpdatedFilter));
  };
  const handleClearFilterAll = () => {
    setPage(0);
    setPageCount(1);
    setFilterData(EMPTY_FILTER);
    setFilterDataUpdate(EMPTY_FILTER);
    localStorage.setItem("booking_filter_data", JSON.stringify(EMPTY_FILTER));
    getBookingListing(EMPTY_FILTER);
    handleCloseFilterDrawer();
  };
  const handleDeleteAllChips = (value) => {
    if (value == "sort_by") {
      setFilterData({
        ...filterData,
        ["sort_by"]: "",
      });
      const UpdatedFilter = {
        ...filterData,
        ["sort_by"]: "",
      };
      setFilterDataUpdate({
        ...filterDataUpdate,
        ["sort_by"]: "",
      });
      setPage(0);
      setPageCount(1);
      getBookingListing(filterData, "sort_by");
      localStorage.setItem(
        "booking_filter_data",
        JSON.stringify(UpdatedFilter)
      );
    }
    if (value == "booking_status") {
      setFilterData({
        ...filterData,
        ["booking_status"]: null,
      });
      const UpdatedFilter = {
        ...filterData,
        ["booking_status"]: null,
      };
      setFilterDataUpdate({
        ...filterDataUpdate,
        ["booking_status"]: null,
      });
      setPage(0);
      setPageCount(1);
      getBookingListing(filterData, "booking_status");
      localStorage.setItem(
        "booking_filter_data",
        JSON.stringify(UpdatedFilter)
      );
    }
    if (value == "date") {
      setFilterData({
        ...filterData,
        ["filter_by_dates"]: false,
        ["start_date"]: null,
        ["end_date"]: null,
      });
      const UpdatedFilter = {
        ...filterData,
        ["filter_by_dates"]: false,
        ["start_date"]: null,
        ["end_date"]: null,
      };
      setFilterDataUpdate({
        ...filterDataUpdate,
        ["filter_by_dates"]: false,
        ["start_date"]: null,
        ["end_date"]: null,
      });
      setPage(0);
      setPageCount(1);
      getBookingListing(filterData, "date");
      localStorage.setItem(
        "booking_filter_data",
        JSON.stringify(UpdatedFilter)
      );
    }
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("booking_filter_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      setFilterData(filter_data);
      setFilterDataUpdate(filter_data);
    }
    getBookingListing(filter_data);
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row section-space">
          <div className="col-sm-12 col-md-6">
            <h2>Bookings</h2>
          </div>
          <div className="col-sm-12 col-md-6 text-end">
            <button
              onClick={handleOpenDrawer}
              className="small-contained-button me-2 mt-1 mb-4"
            >
              Add New Booking
            </button>

            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filter &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <div className="row mb-2">
          {filterDataUpdate.sale_page.length == 0 &&
          filterDataUpdate.booking_status == "" &&
          filterDataUpdate.start_date == null ? (
            ""
          ) : (
            <div className="col-lg-12 col-sm-12">
              <b className="me-3 pt-1 ms-2">Filtered By:</b>
              {filterDataUpdate.sale_page &&
              filterDataUpdate.sale_page.length > 0
                ? filterDataUpdate.sale_page.map((item, index) => {
                    return (
                      <Chip
                        label={item.sale_page_title}
                        onDelete={() => handleDeleteProgramChips(item._id)}
                        className="mb-2 me-1 mt-2"
                        sx={{
                          color: "white",
                        }}
                      />
                    );
                  })
                : ""}
              {filterDataUpdate.booking_status && (
                <Chip
                  label={filterDataUpdate.booking_status.title}
                  onDelete={() => handleDeleteAllChips("booking_status")}
                  className="mb-2 me-1 mt-2"
                  sx={{
                    color: "white",
                  }}
                />
              )}
              {filterDataUpdate.sort_by && (
                <Chip
                  label={show_proper_words(filterDataUpdate.sort_by)}
                  onDelete={() => handleDeleteAllChips("sort_by")}
                  className="mb-2 me-1 mt-2"
                  sx={{
                    color: "white",
                  }}
                />
              )}
              {filterDataUpdate.filter_by_dates && (
                <span>
                  {filterDataUpdate.start_date && (
                    <Chip
                      label={
                        filterDataUpdate.start_date
                          ? "Start Date: " +
                            moment(
                              filterDataUpdate.start_date
                                ? filterDataUpdate.start_date
                                : new Date()
                            ).format("DD-MM-YYYY") +
                            " " +
                            "End Date: " +
                            moment(
                              filterDataUpdate.end_date
                                ? filterDataUpdate.end_date
                                : new Date()
                            ).format("DD-MM-YYYY")
                          : ""
                      }
                      onDelete={() => handleDeleteAllChips("date")}
                      className="mb-2 me-1 mt-2"
                      sx={{
                        color: "white",
                        // backgroundColor: get_root_value("--portal-theme-primary"),
                      }}
                    />
                  )}
                </span>
              )}
              <span
                className="anchor-style ms-2 pt-1"
                onClick={() => handleClearFilterAll()}
                style={{ color: get_root_value("--portal-theme-primary") }}
              >
                Clear All
              </span>
            </div>
          )}
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={bookingsData}
          custom_search={{
            searchText: filterData.search_text,
            setSearchText: (e) => {
              handleChaneOthers("search_text", e);
            },
            handleSubmit: searchFunction,
          }}
          className="card-with-background mt-1"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
          text={"Total Bookings"}
        />

        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete this Booking?"}
          handleAgree={handleDelete}
        />

        <CustomDrawer
          isOpenDrawer={drawerState}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          pageTitle="Add New Booking"
          componentToPassDown={
            <AddOrUpdateBooking
              formType="ADD"
              dataList={searchFunction}
              onCloseDrawer={handleCloseDrawer}
            />
          }
        />

        <CustomDrawer
          isOpenDrawer={editDrawerState}
          onOpenDrawer={handleOpenEditDrawer}
          onCloseDrawer={handleCloseEditDrawer}
          pageTitle="Edit Booking"
          componentToPassDown={
            <AddOrUpdateBooking
              editValues={bookingId}
              dataList={searchFunction}
              onCloseDrawer={handleCloseEditDrawer}
              formType="EDIT"
            />
          }
        />

        <GeneralPopUpModel
          open={changeStatus}
          setOpen={setChangeStatus}
          title={"Change Booking Status"}
          componentToPassDown={
            <ChangeBookingStatus
              selectedObject={selectedBooking}
              bookingsData={searchFunction}
              setOpen={setChangeStatus}
            />
          }
        />

        <CustomDrawer
          isOpenDrawer={passBookingDrawer}
          onOpenDrawer={handleOpenPassBookingDrawer}
          onCloseDrawer={handleClosePassBookingDrawer}
          pageTitle="Pass Booking"
          componentToPassDown={
            <PassBooking
              selectedBooking={selectedBooking}
              dataList={searchFunction}
              onCloseDrawer={handleClosePassBookingDrawer}
            />
          }
        />

        <CustomDrawer
          isOpenDrawer={filterDrawerStare}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filter"
          componentToPassDown={
            <BookingFilter
              filterData={filterData}
              handleChaneOthers={handleChaneOthers}
              handleSubmitFilter={searchFunction}
              handleClearFilterAll={handleClearFilterAll}
              handleClearFilter={handleClearFilter}
            />
          }
        />
      </div>
    </>
  );
}
