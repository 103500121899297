import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { get_token_for_a_video_stream } from "src/DAL/Community/Community";
import ZoomVideo from "@zoom/videosdk";
import { CircularProgress } from "@mui/material";
import { useContentSetting } from "src/Hooks/ContentSettingState";

let client = null;

let stream;

function VideoStream({ post_id, thumbnail, openDetailModal }) {
  const { userInfo, adminTimeZone, socket } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [mediaStream, setMediaStream] = useState();
  const [zmClient, setZmClient] = useState();
  const [participants, setParticipants] = useState([]);

  const leave_stream = useCallback(() => {
    zmClient.leave();
  }, [zmClient]);

  useEffect(async () => {
    const result = await get_token_for_a_video_stream(post_id);
    console.log(result, "--token-result");
    if (result.code === 200) {
      const token = result.feed.zoom_token;
      const topic = post_id;

      const client = ZoomVideo.createClient();
      client.init("en-US", `CDN`);
      client
        .join(topic, token, `${userInfo.first_name} ${userInfo.last_name}`, "")
        .then(() => {
          stream = client.getMediaStream();
          const _participants = client.getAllUser();
          const host = client.getSessionHost();
          console.log(host, "userId");

          stream.startAudio().then((res) => {
            stream.muteAudio();
          });

          console.log(stream.isSupportHDVideo(), "stream.isSupportHDVideo()");

          stream
            .renderVideo(
              document.querySelector("#participants-canvas"),
              host.userId,
              1280,
              720,
              0,
              0,
              3
            )
            .then(() => {
              window.scrollBy({ left: 10 });
            });
          console.log(stream.getVideoMaxQuality(), "getVideoMaxQuality");
          setZmClient(client);
          setMediaStream(stream);
        })
        .catch((error) => {
          enqueueSnackbar(`${error.type}: ${error.reason}`, {
            variant: "error",
          });
        });

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }

    return () => {
      // client.leave();
    };
  }, []);

  useEffect(() => {
    return () => {
      //  Stop stream when popup close
      if (window.location.pathname.search("feed-detail") < 0) {
        leave_stream();
      }
    };
  }, [leave_stream]);

  if (isLoading) {
    return (
      <div className="w-100 pt-5 text-center">
        <CircularProgress
          style={{ height: 20, width: 20 }}
          className=""
          color="primary"
        />
        ;
      </div>
    );
  }

  return (
    <div className="pt-5">
      {/* <button
        onClick={() => {
          console.log(
            mediaStream.getVideoMaxQuality(),
            "mediaStream.getVideoMaxQuality()"
          );
        }}
      >
        Checking
      </button> */}
      <canvas id="participants-canvas" style={{ width: "100%" }}></canvas>
    </div>
  );
}

export default VideoStream;
