import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LeftPart from "./LeftPart";
import RightPart from "./RightPart";
import MainPart from "./MainPart";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import { get_community_content_delegate } from "src/DAL/Community/Community";
import { profilePlaceholder } from "src/assets";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import RightPartEvent from "./RightPartEvents";
import CurrentUpcomingEvents from "./CurrentUpcomingEvents";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function Community({
  event_id,
  event_category,
  event_slug,
  current_events_array,
  upcoming_events_array,
  eventDetail,
  list_type,
  is_scheduled,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, consultantInfo, isAccess } = useContentSetting();
  const [isLoading, setIsLoading] = useState(false);
  const [salePagesList, setSalePagesList] = useState([]);
  const [feedSetting, setFeedSetting] = useState({});
  const [affiliateUrl, setAffiliateUrl] = useState("");
  const [cosmosData, setCosmosData] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [tabOptions, setTabOptions] = useState([]);

  let get_user_level = () => {
    let user_leve_type = "all";
    if (list_type == "the_source") {
      user_leve_type = "dynamite";
    } else if (list_type == "the_cosmos") {
      user_leve_type = isAccess.cosmos_feeds_filters
        ? isAccess.default_filter
        : userInfo?.team_type;
    }
    return user_leve_type;
  };

  const [userType, setUserType] = useState(get_user_level());

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getCommunityContent = async () => {
    setIsLoading(true);
    const result = await get_community_content_delegate(list_type);
    if (result.code === 200) {
      setCosmosData(result);
      setFeedSetting(result.feed_setting);
      setSalePagesList(result.sale_pages);
      setAffiliateUrl(result?.affiliate_member?.affiliate_url_name);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let width = screen.width;
    if (width <= 991) {
      const TABS_OPTIONS = [
        {
          title: "Feeds",
          component: (
            <>
              <MainPart
                event_id={event_id}
                feedSetting={feedSetting}
                list_type={list_type}
                is_scheduled={is_scheduled}
                userType={userType}
                handleChangeUserType={handleChangeUserType}
              />
            </>
          ),
        },
        {
          title: "Notice board & Events",
          component: (
            <LeftPart
              event_category={event_category}
              event_id={event_id}
              event_slug={event_slug}
              data={cosmosData}
              list_type={list_type}
            />
          ),
        },
        {
          title: "Leaderboard",
          component:
            list_type == "event" ? (
              <RightPartEvent
                upcomingEvents={upcoming_events_array}
                currenEvents={current_events_array}
                eventDetail={eventDetail}
              />
            ) : (
              <RightPart
                data={cosmosData}
                salePagesList={salePagesList}
                affiliateUrl={affiliateUrl}
              />
            ),
        },
      ];
      setTabOptions(TABS_OPTIONS);
    }
  }, [cosmosData, userType]);

  useEffect(() => {
    if (list_type == "event") {
      setUserType("all");
    } else {
      getCommunityContent();
      setUserType(get_user_level());
    }
  }, [list_type, is_scheduled]);

  let get_height = (id) => {
    if (!isLoading) {
      setTimeout(() => {
        var rightCol = document.getElementById(id);
        if (rightCol) {
          var topPosition = window.innerHeight - rightCol.offsetHeight - 120;
          if (topPosition > 0) {
            topPosition = 80;
          }
          rightCol.style.top = topPosition + "px";
        }
      }, 0);
    }
  };

  const updateSidebarPosition = () => {
    get_height("sidebar-left");
    get_height("sidebar-right");
  };

  useEffect(() => {
    updateSidebarPosition();
    window.addEventListener("scroll", updateSidebarPosition);
  }, [isLoading]);

  const handleChangeUserType = (event) => {
    setUserType(event.target.value);
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className={`container new-community-page`}>
      {list_type == "the_cosmos" && (
        <section className="banner-section mb-5">
          <div className="container position-relative">
            <img
              src={
                consultantInfo?.the_cosmos_banner_image &&
                s3baseUrl + consultantInfo?.the_cosmos_banner_image
              }
              alt=""
              className="img-fluid banner-bg-image"
            />
            <div className="jade-div">
              <img
                src={
                  userInfo.image
                    ? s3baseUrl + userInfo.image.thumbnail_1
                    : profilePlaceholder
                }
                alt=""
                className="jade-img"
              />
            </div>
          </div>
        </section>
      )}

      <div className="row ">
        <div className="col-md-12 col-lg-3 order-2 order-lg-1 pb-4 d-none d-lg-block">
          <div className="sticky-lg-top" id="sidebar-left">
            <LeftPart
              event_category={event_category}
              event_id={event_id}
              event_slug={event_slug}
              data={cosmosData}
              list_type={list_type}
            />
          </div>
        </div>
        <div className="mobile-case d-block d-lg-none">
          <MUICustomTabs
            data={tabOptions}
            value={tabValue}
            handleChange={handleChange}
            className="mt-4"
          />
        </div>
        <div className="col-md-12 col-lg-6 order-1 order-lg-2 community-main-section pb-4 d-none d-lg-block">
          {tabOptions.length < 1 && (
            <MainPart
              event_id={event_id}
              feedSetting={feedSetting}
              list_type={list_type}
              userType={userType}
              setUserType={setUserType}
              handleChangeUserType={handleChangeUserType}
              is_scheduled={is_scheduled}
            />
          )}
        </div>
        <div className="col-md-12 col-lg-3 order-3 pb-4 d-none d-lg-block">
          <div className="sticky-lg-top" id="sidebar-right">
            {list_type == "event" ? (
              <div className="community-left-sidebar">
                <CurrentUpcomingEvents
                  upcomingEvents={upcoming_events_array}
                  currenEvents={current_events_array}
                  eventDetail={eventDetail}
                />
              </div>
            ) : (
              <RightPart
                data={cosmosData}
                userType={userType}
                handleChangeUserType={handleChangeUserType}
                salePagesList={salePagesList}
                affiliateUrl={affiliateUrl}
                list_type={list_type}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Community;
