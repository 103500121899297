import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  active_booking_status_api,
  update_booking_status_api,
} from "src/DAL/Booking/bookingApi";
import {
  get_all_member,
  get_live_dynamite_event_another_member,
} from "src/DAL/LiveEvents/EventMembers/EventMembers";
import { addPersonalNote } from "src/DAL/member/Member";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { s3baseUrl } from "src/config/config";

export default function AddAsInternalNote({
  selectedObject,
  setOpen,
  bookingsData,
}) {
  const [inputs, setInputs] = useState({
    booking_status_info: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [member, setMember] = useState(null);
  const [serchText, setSerchText] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleChangeStatus = async () => {
    setOpenStatus(false);
    setIsLoading(true);
    let note = "";
    let imageString = "";
    let dis = "";
    if (
      selectedObject.feed_type == "image" ||
      selectedObject.feed_type == "general"
    ) {
      dis = `<p>${selectedObject.description}</p>`;
      let img_array =
        selectedObject.feed_images.length > 0 &&
        selectedObject.feed_images.map((item, index) => {
          return `<img src=${`${s3baseUrl + item.thumbnail_1}`}>`;
        });
      if (img_array.length > 0) {
        imageString = img_array.join(" <br/>");
      }
      note = `${imageString ? imageString + "<br/>" + dis : dis}`;
    } else if (selectedObject.feed_type == "video") {
      imageString = selectedObject.video_url;
      dis = `<p>${selectedObject.description}</p>`;
      note = `${imageString + "<br/><br/>" + dis}`;
    } else if (selectedObject.feed_type == "embed_code") {
      imageString = selectedObject.embed_code;
      dis = `<p>${selectedObject.description}</p>`;
      note = `${imageString + "<br/><br/>" + dis}`;
    }
    console.log(note, "notenote");
    let postData = {
      personal_note: note,
      member_id: member._id,
    };
    const result = await addPersonalNote(postData);
    if (result.code === 200) {
      setOpen(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenStatus(true);
  };
  const getMember = async () => {
    const result = await get_all_member(serchText);
    if (result.code === 200) {
      setPersonName(result.member);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getMembercalled = async () => {
    const result = await get_all_member();
    if (result.code === 200) {
      setPersonName(result.member);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  useEffect(() => {
    getMember();
  }, [serchText]);

  console.log(
    selectedObject,
    "selectedObjectselectedObjectselectedObjectselectedObject"
  );
  return (
    <div className="container">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <Autocomplete
            id="tags-outlined"
            options={personName}
            getOptionLabel={(option) =>
              option.first_name + " (" + option.email + ")"
            }
            filterSelectedOptions
            value={member}
            onChange={(event, newValue) => {
              setMember(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Members"
                required
                placeholder="Members"
                onChange={handleSearch}
                onFocus={() => setSerchText("")}
              />
            )}
          />
        </div>

        <CustomConfirmation
          open={openStatus}
          setOpen={setOpenStatus}
          title={"Are you sure you want to add as a personal note ?"}
          handleAgree={handleChangeStatus}
        />
        <div class="col-12 mt-3">
          <button class="small-contained-button w-100">Add Note</button>
        </div>
      </form>
    </div>
  );
}
