import { Autocomplete, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";

export default function MUIAutocomplete({
  inputLabel,
  selectedOption,
  setSelectedOption,
  optionsList,
  multiple,
  required,
  disabled,
  setCustomSearch,
  name,
  size,
  index,
  limitTags,
}) {
  const [inputValue, setInputValue] = useState("");
  const [dataList, setDataList] = useState([]);
  const [isPasted, setIsPasted] = useState(false);
  useEffect(() => {
    let date_list = [];
    optionsList.map((item) => {
      if (multiple) {
        let find = selectedOption.find(
          (selected) => selected[name] == item[name]
        );
        if (!find) {
          return date_list.push(item);
        }
      } else if (!selectedOption || selectedOption[name] !== item[name]) {
        return date_list.push(item);
      }
    });

    setDataList(date_list);
  }, [optionsList, selectedOption]);

  const handleChangeInput = (newInputValue) => {
    if (isPasted) {
      setInputValue(newInputValue.trim());
    } else {
      setInputValue(newInputValue);
    }

    if (setCustomSearch) {
      setCustomSearch(newInputValue);
    }

    setIsPasted(false); // Reset the flag after handling the input
  };

  let array_required = false;
  if (required) {
    if (multiple && selectedOption.length == 0) {
      array_required = true;
    } else {
      if (!selectedOption) {
        array_required = true;
      }
    }
  }
  const handlePaste = (e) => {
    setIsPasted(true);
  };
  return (
    <Autocomplete
      disabled={disabled}
      multiple={multiple}
      value={selectedOption}
      limitTags={limitTags ? limitTags : 3}
      onChange={(event, newValue) => {
        setSelectedOption(newValue, index);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        handleChangeInput(newInputValue);
      }}
      className="w-100 mui-select autocomplete-mui"
      PaperComponent={({ children }) => (
        <Paper className="autocomplete-paper">{children}</Paper>
      )}
      size={size ? size : "medium"}
      id="controllable-states-demo"
      options={dataList}
      getOptionLabel={(option) => option[name]}
      renderInput={(params) => {
        const inputProps = params.inputProps;
        inputProps.autoComplete = "off";
        if (array_required) {
          inputProps.required = true;
        }
        return (
          <TextField
            {...params}
            label={inputLabel}
            placeholder={inputLabel}
            required={array_required}
            disabled={disabled}
            inputProps={{
              ...inputProps,
              onPaste: handlePaste,
            }}
          />
        );
      }}
    />
  );
}
